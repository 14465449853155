<template>
	<div element-loading-background="rgba(0, 0, 0, 0.4)" v-loading.fullscreen.lock="fullscreenLoading" class="flip_box">
		<img class="bj_img" src="../../assets/jubj.svg" alt="" />
		<div class="page-box">
			<!-- :style="{
					backgroundImage: 'url(' + require('@/assets/bookBackg.jpg') + ')',
				}" -->
			<div class="book_div">
				<div class="flip-book" id="demoBookExample">
					<!-- <div class="page page-cover page-cover-top" data-density="hard">
					<div class="page-content">
						<h2>BOOK TITLE</h2>
					</div>
				</div> -->
					<div class="page" v-for="(e, i) in filePage" :key="i">
						<div class="page-content">
							<canvas :id="'pageNum-' + (Number(i) + 1)"></canvas>
							<!-- <div class="page-footer">{{ e }}</div> -->
						</div>
					</div>
					<!-- PAGES .... -->
					<!-- <div
					class="page page-cover page-cover-bottom"
					data-density="hard"
				>
					<div class="page-content">
						<h2>THE END</h2>
					</div>
				</div> -->
				</div>
			</div>
			<div class="btn-box" v-show="is_show_btn">
				<div v-if="store_id == 385" :style="is_mobile ? 'position: absolute;top:6px; left: 0;display: flex;' : ''" class="info-box">
					<p class="p1">获取完整刊物请联系郑老师</p>
					<p class="p2">电话/微信:19980570961</p>
				</div>
				<div class="button-box">
					<div>
						<span @click="btn_prev">上一页</span>
						<div style="padding: 0 20px">
							<span> {{ current }} </span> / <span> {{ pageTotal }} </span>
						</div>
						<span @click="btn_next">下一页</span>
					</div>
					<div v-show="check_root.download_type != 4" @click="download_btn" style="cursor: pointer">下载</div>
				</div>
				<div v-if="store_id == 385 && !is_mobile" style="width: 144px"></div>
			</div>
		</div>
		<el-dialog
			v-model="permissiondialogVisible"
			title=""
			top="20vh"
			width="500px"
			custom-class="dialog_class"
			:before-close="
				() => {
					permissiondialogVisible = false;
				}
			"
		>
			<div v-if="common_init" class="permission_dialog_box">
				<img class="info_img" src="../../assets/info.svg" alt="" />
				<p class="permission_p">{{ log_msg }}</p>
				<div class="permission_div1">
					<img src="../../assets/phone.svg" alt="" />
					<span>{{ common_init.config.info.phone }}（ {{ common_init.config.info.user_name }} ）</span>
				</div>
				<div class="permission_div2">
					<el-button @click="permissiondialogVisible = false">我知道了</el-button>
					<!-- <el-button type="primary">立即入会</el-button> -->
				</div>
			</div>
		</el-dialog>
		<el-dialog
			v-model="logindialogVisible"
			title=""
			top="20vh"
			width="500px"
			custom-class="dialog_class"
			:close-on-click-modal="journal_data ? true : false"
			:close-on-press-escape="journal_data ? true : false"
			:show-close="journal_data ? true : false"
			:before-close="
				() => {
					logindialogVisible = false;
				}
			"
		>
			<div v-if="common_init" class="permission_dialog_box">
				<img class="info_img" src="../../assets/info.svg" alt="" />
				<p class="permission_p">{{ log_msg }}</p>
				<!-- <div class="permission_div1">
					<img src="../../assets/phone.svg" alt="" />
					<span>{{ common_init.config.info.phone }}（ {{ common_init.config.info.user_name }} ）</span>
				</div> -->
				<div class="permission_div2">
					<el-button v-if="journal_data" @click="logindialogVisible = false">我知道了</el-button>
					<el-button @click="login_immediately" type="primary">立即登录</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog
			v-model="hintVisible"
			title=""
			top="20vh"
			custom-class="dialog_class"
			width="500px"
			:before-close="
				() => {
					hintVisible = false;
				}
			"
		>
			<div class="permission_dialog_box">
				<img class="info_img" src="../../assets/info.svg" alt="" />
				<p class="permission_p">请在外部浏览器中打开下载！</p>
			</div>
		</el-dialog>
	</div>
</template>
<script>
let t;
import { pathToBase64, base64ToPath } from '@/utils/image-tools';
import PDFJS from 'pdfjs-dist';
import { PageFlip } from 'page-flip';
export default {
	data() {
		return {
			hintVisible: false,
			pageFlipObj: '',
			pageFlipBoxWidth: 800,
			pageFlipBoxHeight: 800,
			current: 1,
			pageTotal: 1,
			arrayBuffer: null, // arrayBuffer
			pdfPath: '',
			filePage: 0,
			journal_data: '',
			journal_id: '',
			check_root: '',
			common_init: '',
			user_record_type: '',
			permissiondialogVisible: false,
			logindialogVisible: false,
			log_msg: '需要登录后才能查看！',
			is_show_btn: false,
			fullscreenLoading: true,
			label: '',
			store_id: '',
			is_mobile: false,
		};
	},
	created() {
		if (localStorage.is_pc == 0) {
			console.log('手机端');
			this.is_mobile = true;
		} else {
			console.log('PC端');
		}
		// document.title = '电子期刊';
		this.journal_id = this.$route.query.id;
		this.journal_label = this.$route.query.label;
		if (this.$route.query.store_id) {
			this.store_id = this.$route.query.store_id;
		}
	},
	mounted() {
		t = this;
		this.get_journal_check_root();
		this.get_journal_data();
		this.get_common_init();
		if (localStorage.token) {
			this.get_user_record();
		}
	},
	methods: {
		login_immediately() {
			if (window.parent) {
				window.parent.postMessage(
					{
						cmd: 'login',
						params: {
							status: '0',
						},
					},
					'*'
				);
			}
		},
		download_btn() {
			if (localStorage.is_pc == 0 && localStorage.is_weixin == 1) {
				this.hintVisible = true;
			} else {
				if (this.check_root.download_type == 1) {
					this.handleDown({ url: this.journal_data.file, fileName: this.journal_data.title + '.pdf' });
				} else if (this.check_root.download_type == 2) {
					if (localStorage.token) {
						this.handleDown({ url: this.journal_data.file, fileName: this.journal_data.title + '.pdf' });
					} else {
						this.log_msg = '需要登录后才能下载！';
						this.logindialogVisible = true;
					}
				} else if (this.check_root.download_type == 3) {
					if (localStorage.token) {
						if (this.user_record_type) {
							this.handleDown({ url: this.journal_data.file, fileName: this.journal_data.title + '.pdf' });
						} else {
							this.log_msg = '非常抱歉，该账号没有权限下载，如有需求请联系我们';
							this.permissiondialogVisible = true;
						}
					} else {
						this.log_msg = '需要登录后才能下载！';
						this.logindialogVisible = true;
					}
				}
			}
		},
		async get_journal_check_root() {
			const res = await this.$request(
				'/api/pc/file/check_root',
				{
					label: this.journal_label,
				},
				'get'
			);
			this.check_root = res.data;
		},
		async get_journal_data() {
			const res = await this.$request('/api/pc/file/info', { id: this.journal_id, label: this.journal_label }, 'get');
			if (res.code == 1) {
				this.journal_data = res.data;
				function containsIgnoreCase(str, char) {
					const regex = new RegExp(char, 'i'); // 创建不区分大小写的正则表达式
					return regex.test(str); // 使用 test 方法检查是否匹配
				}
				if (this.is_mobile && containsIgnoreCase(this.journal_data.file, '.pdf')) {
					window.location.href = `https://h5.xiebanyun.com/#/pagesB/h5-show-pdf/index?file=${this.journal_data.file}`;
				}
				this.pathToBase64Api(this.journal_data.file);
			} else {
				if (localStorage.token) {
					this.log_msg = '非常抱歉，该账号没有权限查看，如有需求请联系我们';
					this.permissiondialogVisible = true;
				} else {
					this.log_msg = '需要登录后才能查看！';
					this.logindialogVisible = true;
				}
			}
		},
		async get_common_init() {
			const res = await this.$request('/api/common/init', {}, 'get');
			this.common_init = res.data;
		},
		async get_user_record() {
			try {
				const res = await this.$request('/api/pc/user/record', {}, 'get');
				if (res.code != 0) {
					this.user_record_type = res.data.type;
				}
			} catch (error) {}
		},

		btn_prev() {
			this.pageFlipObj.flipPrev(); // Turn to the previous page (with animation)
		},
		btn_next() {
			this.pageFlipObj.flipNext(); // Turn to the next page (with animation)
		},

		// 创建canvas
		createCanvas(val) {
			// 清空节点下数据
			// document.getElementById('pdf_img_box').innerHTML = ''
			// 使用getTextContent获取pdf内容
			PDFJS.getDocument(val).promise.then((el) => {
				let filePage = (this.filePage = el.numPages);
				if (filePage == 2 && !this.is_mobile) {
					this.current = 2;
				}
				setTimeout(() => {
					for (let i = 1; i <= filePage; i++) {
						// let canvas = document.createElement('canvas')
						// canvas.id = `pageNum-${i}`
						// let context = canvas.getContext('2d')
						// document.getElementById('pdf_img_box').append(canvas)
						let context = document.getElementById(`pageNum-${i}`).getContext('2d');
						// 	// 渲染canvas
						this.openPage(el, i, context);
					}
				}, 50);
				setTimeout(() => {
					this.pageFlipBoxWidth = this.viewport_size.width * 2;
					this.pageFlipBoxHeight = this.viewport_size.height * 2;
					if (this.viewport_size.width > this.viewport_size.height) {
						document.getElementsByClassName('page-box')[0].style.width = '90%';
						// document.getElementsByClassName('page-box')[0].style.paddingTop = this.is_mobile ? '8vh' : '12vh';
					} else {
						document.getElementsByClassName('page-box')[0].style.width = '60%';
						// document.getElementsByClassName('page-box')[0].style.paddingTop = this.is_mobile ? '6vh' : '10vh';
					}
					this.loadBook();
					this.is_show_btn = true;
					this.fullscreenLoading = false;
					if (this.filePage == 1 && !this.is_mobile) {
						document.getElementsByClassName('flip-book')[0].style.backgroundColor = 'transparent';
						document.getElementsByClassName('flip-book')[0].style.boxShadow = 'none';
						document.getElementsByClassName('flip-book')[0].style.marginLeft = `${document.getElementsByClassName('flip-book')[0].getBoundingClientRect().width / 4}px`;
					}
				}, 300);
			});
		},
		loadBook() {
			this.pageFlipObj = new PageFlip(document.getElementById('demoBookExample'), {
				width: this.pageFlipBoxWidth, // base page width
				height: this.pageFlipBoxHeight, // base page height
				size: 'stretch',
				minWidth: 200,
				maxWidth: 1000,
				minHeight: 100,
				maxHeight: 1350,
				flippingTime: 600,
				maxShadowOpacity: 0.5, // Half shadow intensity
				mobileScrollSupport: false, // disable content scrolling on mobile devices
				// set threshold values:
				// usePortrait: false, //启用切换到纵向模式
				// showCover: true,
			});
			// load pages
			this.pageFlipObj.loadFromHTML(document.querySelectorAll('.page'));
			this.pageTotal = this.pageFlipObj.getPageCount();
			this.pageFlipObj.on('flip', (e) => {
				if (this.is_mobile) {
					this.current = e.data + 1;
				} else {
					this.current = e.data + 2;
				}
			});
		},
		// 渲染canvas
		openPage(pdfFile, pageNumber, context) {
			// 获取PDF文档中的各个页面
			pdfFile.getPage(pageNumber).then((page) => {
				// 设置展示比例
				let scale = 2;
				// 获取pdf尺寸
				let viewport = page.getViewport(scale);
				let canvas = context.canvas;
				if (!this.viewport_size) {
					this.viewport_size = viewport;
				}
				canvas.width = viewport.width;
				if (this.viewport_size.width == viewport.width) {
					canvas.height = viewport.height;
				} else {
					canvas.height = this.viewport_size.height * (viewport.width / this.viewport_size.width);
				}
				canvas.style.width = '100%';
				canvas.style.height = '100%';
				let model = {
					canvasContext: context,
					viewport: viewport,
				};
				// 渲染PDF
				page.render(model);
			});
		},
		// url转base64
		pathToBase64Api(pdfPath) {
			pathToBase64(pdfPath).then((base64) => {
				//  读取文件内容
				let file = this.dataURLtoFile(base64, 'pdf文件');
				// 使用FileReader对象，web应用程序可以异步的读取存储在用户计算机上的文件(或者原始数据缓冲)内容，可以使用File对象或者Blob对象来指定所要处理的文件或数据
				let fileReader = new FileReader();
				// 异步按字节读取文件内容，结果用ArrayBuffer对象表示
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = (e) => {
					let arrayBuffer = (this.arrayBuffer = e.target.result);
					// 创建canvas节点
					this.createCanvas(arrayBuffer);
				};
			});
		},
		dataURLtoFile(dataurl, filename) {
			//将base64转换为文件
			var arr = dataurl.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		},
		// 下载
		handleDown(row) {
			const _that = this; // 解决跨域下载，不能重命名文件名
			this.getBlob(row.url, function (blob) {
				_that.saveAs(blob, row.fileName);
			});
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest();
			xhr.open('GET', url, true);
			xhr.responseType = 'blob';
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response);
				}
			};
			xhr.send();
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename);
			} else {
				var link = document.createElement('a');
				var body = document.querySelector('body');
				link.href = window.URL.createObjectURL(blob);
				link.download = filename; // fix Firefox
				link.style.display = 'none';
				body.appendChild(link);
				link.click();
				body.removeChild(link);
				window.URL.revokeObjectURL(link.href);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.permission_dialog_box {
	width: 100%;
	text-align: center;

	.info_img {
		width: 38px;
		height: 38px;
		margin: 20px;
	}

	.permission_p {
		font-size: 15px;
		font-weight: 400;
		color: #303133;
	}

	.permission_div1 {
		margin: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 16px;
			height: 16px;
			margin: 0 10px;
		}

		span {
			font-size: 14px;
			font-weight: 400;
			color: #2d91e3;
		}
	}

	.permission_div2 {
		margin-top: 30px;
	}
}

.flip_box {
	min-width: 1440px;
	display: flex;
	min-height: 100vh;
}

.bj_img {
	position: fixed;
	z-index: -10;
	width: 100%;
	height: 100%;
	min-width: 1440px;
}

.page-box {
	// max-width: 1440px;
	// max-height: 950px;
	width: 100%;
	margin: auto;
	padding: 0 15px;
	// padding-top: 70px;
	// background-image: linear-gradient(to right, #c59280, #724a47);
	overflow: hidden;

	.btn-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		.info-box {
			.p1,
			.p2 {
				color: white;
				font-size: 12px;
				text-shadow: 0px 0px 10px rgb(0, 0, 0);
				margin-right: 20px;
			}
		}

		.button-box {
			margin: 20px auto;
			width: 20px;
			flex-grow: 1;
			display: flex;
			// flex-direction: column;
			justify-content: center;
			align-items: center;

			> div {
				margin: 10px;
				display: flex;
				background: rgba(48, 49, 51, 0.4);
				border-radius: 24px;
				font-size: 13px;
				font-weight: 400;
				color: #ffffff;
				padding: 5px 20px;

				> span {
					cursor: pointer;
				}
			}
		}
	}

	.flip-book {
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
		display: none;
		background-size: cover;
		background: #ffffff;
	}

	.page {
		padding: 20px;
		background-color: hsl(35, 55, 98);
		color: hsl(35, 35, 35);
		border: solid 1px hsl(35, 20, 70);
		overflow: hidden;

		.page-footer {
			height: 30px;
			// border-top: solid 1px hsl(35, 55, 90);
			font-size: 80%;
			color: hsl(35, 20, 50);
			position: absolute;
			width: 90%;
			bottom: 0;
		}

		&.--left {
			// for left page (property will be added automatically)
			border-right: 0;
			box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
		}

		&.--right {
			// for right page (property will be added automatically)
			border-left: 0;
			box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);

			.page-footer {
				text-align: right;
			}
		}

		&.hard {
			// for hard page
			background-color: hsl(35, 50, 90);
			border: solid 1px hsl(35, 20, 50);
		}

		&.page-cover {
			background-color: rgb(233, 190, 130);
			color: hsl(35, 35, 35);
			border: solid 1px hsl(35, 20, 50);

			h2 {
				text-align: center;
				padding-top: 50%;
				font-size: 210%;
			}

			&.page-cover-top {
				box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
			}

			&.page-cover-bottom {
				box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.flip_box {
		min-width: 100vw;
		width: 100vw;
	}

	.page-box {
		width: 100vw !important;

		.button-box {
			width: 100%;
			display: flex;

			> div {
				display: flex;

				> span {
					white-space: nowrap;
				}

				> div {
					display: flex;
					align-items: center;

					> span {
						white-space: nowrap;
					}
				}
			}
		}
	}
}
</style>
<style>
.dialog_class {
	width: 80% !important;
	max-width: 500px !important;
}
</style>
